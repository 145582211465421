const App = () => {
    const youtubeVideoId = "4M3ZY9NJ-qs";
    const chzzkChannelId = "0b33823ac81de48d5b78a38cdbc0ab94";

    return (
        <div className="h-screen flex">
            <div className="w-full h-full">
                <iframe src={`https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1`}
                        title="YouTube"
                        frameBorder="0"
                        allowFullScreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        width="100%" height="100%"></iframe>
            </div>
            <div className="min-w-[650px] h-full flex flex-col">
                <div className="min-h-[550px]">
                    <iframe src={`https://chzzk.naver.com/live/${chzzkChannelId}`}
                            title="Chzzk"
                            frameBorder="0"
                            allowFullScreen
                            scrolling="no"
                            height="100%"
                            width="100%"></iframe>
                </div>

                <div className="fixed mt-[430px] min-w-[650px]" style={{height: 'calc(100% - 430px)'}}>
                    <iframe
                        className="absolute"
                        id="chat_embed"
                        src={`https://chzzk.naver.com/live/${chzzkChannelId}/chat`}
                        title="ChzzkChatting"
                        width="100%"
                        height="100%"></iframe>
                </div>
            </div>
        </div>
    );
}

export default App;
